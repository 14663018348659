<template>
  <div class="content row-flex-start" style="width:100%; height: 300px;ovorflow-x:auto">
    <div class="left_map" id="left_map"  @click="showChinaMap"></div>
    <!-- <div class="right_opetate row-center" id="right_opetate"> -->
    <!-- </div> -->
  </div>
</template>

<script>
import echarts from "echarts";
import china from 'echarts/map/json/china.json'
import axios from 'axios'
echarts.registerMap('china', china)
  var provinces = ['shanghai', 'hebei','shanxi','neimenggu','liaoning','jilin','heilongjiang','jiangsu','zhejiang','anhui','fujian','jiangxi','shandong','henan','hubei','hunan','guangdong','guangxi','hainan','sichuan','guizhou','yunnan','xizang','shanxi1','gansu','qinghai','ningxia','xinjiang', 'beijing', 'tianjin', 'chongqing', 'xianggang', 'aomen', 'taiwan'];
  var provincesText = ['上海', '河北', '山西', '内蒙古', '辽宁', '吉林','黑龙江',  '江苏', '浙江', '安徽', '福建', '江西', '山东','河南', '湖北', '湖南', '广东', '广西', '海南', '四川', '贵州', '云南', '西藏', '陕西', '甘肃', '青海', '宁夏', '新疆', '北京', '天津', '重庆', '香港', '澳门', '台湾'];
export default {
  created() {
    // console.log(this.info);
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
  },
  data() {
    return {
      map :{},
      cityOpt: [],
      mapForm: {},
      mapData: [{ name: "海门", value: 100 }],
      mapOpt: [],
      provinceList: [],
      cityList: [],
      initMapStatus: false,
    };
  },
  props: {
    info: {
      type: Array,
    },
  },
  methods: {
    getMapOpt(place) {
      let option = {
      backgroundColor: '#fff',
      // title: {
      //     text: "mapDemo",
      //     subtext: "data from map",
      //     left: "center"
      //   },
      geo: {
        map: place?place:'china',
        show: true,
        roam: true,
        label: {
            emphasis: {
                show: false
            }
        },
        layoutCenter: ['50%', '45%'],
        layoutSize: 350,
        itemStyle: {
            // normal: {
            //     areaColor: '#323c48',
            //     borderColor: '#111',
            //     borderWidth: 0.6,
            // },
            // emphasis: {
            //     areaColor: '#2a333d'
            // }
            areaColor: "#AB82FF", //地图区域的颜色(没有数据时会按照这个颜色显示)
            borderColor: "#fff",  //地图区域的边框
            borderWidth: 0.6,
        },
        emphasis: {  //高亮的显示设置
            label: {
              color: "#fff",
            },
            itemStyle: {
              areaColor: "#B22222",
            },
          },
          label: {
            emphasis: {
            show: true,  //开启悬浮事件
            color: '#D4EEFF'//字体颜色
               }
            },
          select: { //选中显示设置
            label: {
              color: "#fff",
            },
            itemStyle: {
              areaColor: "#9DE3FF",
            },
          },
       },
      }
      return option
    },
    //显示中国地图
    showChinaMap(){
      let option = this.getMapOpt()
      this.map.setOption(option, true);
      if (this.initMapStatus) {
        this.setProvinceNum();
      }
    },
    setProvinceNum() {
      // console.log(this.info);
      this.$emit('setKHNum',this.info)
    },
    //显示各省地图
    getProvinceMapOpt(provinceAlphabet){
      this.initMapStatus = true;
      // console.log(provinceAlphabet);
      axios.get('/province/'+provinceAlphabet+'.json').then((s)=>{
        echarts.registerMap(provinceAlphabet, s.data)
        let option = this.getMapOpt(provinceAlphabet)
        this.map.setOption(option, true);
      })
    },
    setCityNum(name) {
      let status = false;
      for (let i = 0; i < this.info.length; i++) {
        let item = this.info[i];
        if (item.adderss.includes(name)) {
          status = true;
          this.$emit('setKHNum',item.cityList);
          break;
        }
      }
      if (!status) {
        this.$emit('setKHNum',[])
      }
    },
    initMap() {
      var dom = document.getElementById("left_map");
      this.map = echarts.init(dom);
      let option = this.getMapOpt()
      if (option && typeof option === "object") {
        this.map.setOption(option, true);
      }
      this.map.on('click', (param)=> {
        event.stopPropagation()// 阻止冒泡
        // 找到省份名
        let provinceIndex = provincesText.findIndex(x=>{
          // console.log(param.name);
          return param.name ===x
        })
        if(provinceIndex===-1)return
        let provinceAlphabet = provinces[provinceIndex]
        // 重新渲染各省份地图
        this.getProvinceMapOpt(provinceAlphabet)
        this.setCityNum(param.name);
      })
    }
  },
  computed: {}
};
</script>

<style>
.left_map {
  width: 100%;
  height: 100%;
}
/* .right_opetate {
  flex: 1;
  height: 100%;
  background: #404a59
} */
/* .map_form {
} */
</style>